/* src/about.css */
.about__wrapper {
  display: flex;
  column-gap: 2rem;
  padding-bottom: 80px;
}

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

.active.fade-left1 {
  animation: fade-left 1.5s ease-in;
}

.active.fade-left2 {
  animation: fade-left 2s ease-in;
}

@keyframes fade-left {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.about__content,
.about__img {
  width: 50%;
}

.about__img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
  padding-top: 3.5rem;
}

.about__content-desc {
  margin-top: 30px;
}

.choose__item-wrapper {
  margin-top: 40px;
}

.choose__us-item {
  padding: 20px 20px 20px 0px;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 15px;
}

.choose__us-icon {
  width: 50px;
  height: 60px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

.choose__us-header {
  text-align: center;
}

.choose__us-icon i {
  color: var(--primary-color);
  font-size: 2rem;
}

.choose__us-title {
  color: #fff;
  font-weight: 500;
  text-align: start;
}

.light-theme .choose__us-title {
  color: #0c123d !important;
}

@media only screen and (max-width: 992px) {
  .about__content h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .about__wrapper {
    flex-direction: column;
    align-items: center;
  }

  .about__content,
  .about__img {
    width: 100%;
  }

  .about__img img {
    padding-top: 0;
  }

  .choose__us-item {
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .choose__us-icon {
    margin-bottom: 10px;
  }

  .choose__us-title {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .choose__us-title {
    font-size: 1.2rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .choose__us-icon i {
    font-size: 1.5rem;
  }
}
