/* RESET RULES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --white: white;
  --gray: #999;
  --lightgray: whitesmoke;
  --popular: #ffdd40;
  --starter: #379cf6;
  --essential: #213296;
  --professional: #1a0f4f;
}

.top-content {
  text-align: center;
  margin-bottom: 50px;
}

.body1 {
  overflow-y: auto;
  height: 98vh;
}

table {
  border-collapse: collapse;
}

section:nth-child(1),
section:nth-child(3) {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.plan {
    color: var(--small-text-color);
}

/* TABLE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.table-wrapper {
  background: var(--color-primary);
  overflow-x: auto;
}

table {
  position: relative;
  text-align: center;
  width: 100%;
}

table thead {
  background: var(--color-primary);
  transition: box-shadow 0.2s;
}

table tr {
  display: flex;
}

table th,
table td {
  width: 25%;
  min-width: 150px;
}

.svg1 {
    color: var(--small-text-color);
}

table th:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 1.3;
  padding: 0 10px;
}

table th:nth-child(1) .svg-wrapper {
  margin-top: 10px;
}

table th:nth-child(1) svg {
  width: 22px;
  height: 22px;
}

table th .heading {
  padding: 1rem;
  color: var(--white);
}

table th:nth-child(2) .heading {
  background: var(--starter);
}

table th:nth-child(3) .heading {
  background: var(--essential);
}

table th:nth-child(4) .heading {
  background: var(--professional);
}

table th .info {
  position: relative;
  padding: 1.5rem 0;
  border-left: 1px solid var(--lightgray);
}

table th .popular1 {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 11px;
  background: var(--popular);
  color: var(--professional);
  padding: 4px 8px;
  border-radius: 2px;
}

table th .amount {
  font-size: 2rem;
}

table th .amount span {
  display: block;
  transform: translateY(-8px);
}

table th:nth-child(2) .amount {
  color: var(--starter);
}

table th:nth-child(3) .amount {
  color: var(--essential);
}

table th:nth-child(4) .amount {
  color: var(--professional);
}

table th,
table th .amount span {
  font-weight: normal;
  font-size: 0.8rem;
  color: var(--small-text-color);
}

table td {
  padding: 10px;
}

table td:not(:first-child) {
  border-left: 1px solid var(--lightgray);
}

table td:first-child {
  font-size: 1rem;
  text-align: left;
}

table svg {
  width: 18px;
  height: 18px;
  color: var(--small-text-color);
}

table svg.not-included {
  fill: var(--gray);
}

table svg.starter {
  fill: var(--starter);
}

table svg.essential {
  fill: var(--essential);
}

table svg.professional {
  fill: var(--professional);
}


/* BODY CLASSES & MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.sticky-table thead tr,
.sticky2-table thead tr {
  z-index: 1;
}

@media screen and (min-width: 780px) {

  
  table th:nth-child(1) {
    padding: 0 20px;
  }
  
  table td {
    padding: 20px;
  }
  
  .sticky-table table thead {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }

  .sticky-table table thead {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
  }

  .sticky2-table table thead {
    position: absolute;
    left: 0;
  }
}