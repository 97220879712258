/* src/Hero.css */
.hero {
  height: 100vh;
  background-image: url('./../../../images/hintergrund.png'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.hero-content {
  max-width: 800px;
  padding: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.hero-title-highlight {
  color: var(--primary-color);
  font-size: 2.5rem;
  font-style: bold;
}

.hero-title-sub {
  font-size: 1.5rem;
}

.hero-title {
  font-size: 2rem;
}

/* Media Queries für kleinere Geräte */
@media (max-width: 992px) {
  .hero-content {
    padding: 40px;
  }

  .hero-title-highlight {
    font-size: 2rem;
  }

  .hero-title-sub {
    font-size: 1.2rem;
  }

  .hero-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 70vh;
  }

  .hero-content {
    padding: 30px;
  }

  .hero-title-highlight {
    font-size: 1.8rem;
  }

  .hero-title-sub {
    font-size: 1rem;
  }

  .hero-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  .hero-content {
    padding: 40px;
  }

  .hero-title-highlight {
    font-size: 1.5rem;
  }

  .hero-title-sub {
    font-size: 0.9rem;
  }

  .hero-title {
    font-size: 1.2rem;
  }

  .btn-gl {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }
}
