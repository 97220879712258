/* src/text.css */
.energy-background {
  background: #660202;
  padding-top: 1px;
  padding-bottom: 1px;
}

.energy-savings-container {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 40px;
  color: white;
  max-width: 800px;
  margin: 40px auto;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-sizing: border-box;
}

.energy-savings-container h1,
.energy-savings-container h2 {
  margin-bottom: 15px;
  color: red;
  text-align: center;
  padding-bottom: 10px;
}

.text-blocks {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  box-sizing: border-box;
}

.text-blocks p {

  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .energy-savings-container {
    padding: 30px;
    margin: 30px;
  }

  .energy-savings-container h1 {
    font-size: 1.5rem;
  }

  .text-blocks {
    max-width: 100%;
    padding: 10px;
  }

  .text-blocks p {
    padding: 15px;
  }
}

@media (max-width: 600px) {
  .energy-savings-container {
    padding: 20px;
    margin: 20px;
  }

  .energy-savings-container h1 {
    font-size: 1.2rem;
  }

  .text-blocks {
    width: 90%;
    padding: 10px;
  }

  .text-blocks p {
    padding: 10px;
    margin-bottom: 15px;
  }
}
