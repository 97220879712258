*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

:root {
    --popular: #ffdd40;
}
.top-content {
    text-align: center;
    margin-bottom: 20px;
}

.body {
    min-height: 100vh;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(1fr, 25%);
}

.pricing-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: min(1600px, 100%);
    margin: auto;
    gap: 15px;
}

.pricing-card{
    flex: 1;
    max-width: auto;
    background: var(--card-bg);
    margin: 20 px 10px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    color: var(--primary-color);
    transition: .3s linear;
}

.pricing-card-header {
    background-color: var(--btn-secondary-bg);
    display: inline-block;
    color: #fff;
    padding: 15px 30px;
    border-radius: 0 0 20px 20px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    transition: .4s linear;
}

.pricing-card:hover .pricing-card-header {
    box-shadow: 0 0 0 36em var(--primary-color);
}

.popular {
    position: sticky;
    float: right !important;
    text-decoration: none;
    top: 10px;
    left: 16rem;
    font-size: 11px;
    background: var(--popular);
    color: var(--btn-secondary-bg);
    padding: 4px 8px;
    border-radius: 5px;
    transform: rotate(20deg);
}

.price {
    font-size: 70px;
    color: var(--primary-color);
    margin: 40px 0;
    transition: .2s linear;
}

.price sup, .price span {
    font-size: 22px;
    font-weight: 700;
}

.pricing-card:hover ,.pricing-card:hover .price {
    color: #fff;
}

.pricing-card li {
    font-size: 16px;
    padding: 10px 0;
    text-transform: uppercase;
}

@media screen and (max-width: 1100px) {
    .pricing-card{
        flex: 50%;
    }
}

@media screen and (max-width: 500px) {
    .price{
        font-size: 2.5rem;
    }
}