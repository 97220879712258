/* src/cta.css */
.cta-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #f4f4f4;
}

.cta-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-image img {
  width: 500px;
  height: 150px;
  border-radius: 8px;
}

.cta-content {
  flex: 1;
  max-width: 500px;
  margin-left: 20px;
  text-align: center;
}

.cta-content h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: red;
}

.cta-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #1888ff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
}

@media (max-width: 992px) {
  .cta-content h2 {
    font-size: 1.8rem;
  }

  .cta-content p {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 768px) {
  .cta-section {
    flex-direction: column;
    text-align: center;
  }

  .cta-image {
    justify-content: center;
    margin-bottom: 20px;
  }

  .cta-content {
    margin-left: 0;
    text-align: center;
  }

  .cta-content h2 {
    font-size: 1.5rem;
  }

  .cta-content p {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 0.8rem;
    padding: 8px 14px;
  }
}

@media (max-width: 600px) {
  .cta-content h2 {
    font-size: 1.2rem;
  }

  .cta-content p {
    font-size: 0.8rem;
  }

  .cta-button {
    font-size: 0.7rem;
    padding: 6px 12px;
  }
}
