.container12 {
    width: 100%;
    justify-content: center;
  }

  .contact-form {
    background: #000;
  }
  
  .contact__container {
    width: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12%;
  }
  
  .kontakt__img {
    width: 50%;
    padding-top: 75px;
  }
  
  .kontakt__img img {
    width: 100%;
    border-radius: 25px;
  }
  
  .container__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .container__top {
    text-align: center;
    padding-bottom: 3rem;
    width: 18rem;
  }
  
  .container__text {
    text-align: center;
    padding-bottom: 3rem;
  }
  
  .contact__options {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    justify-content: center;
  }
  
  .contact__option {
    background: var(--primary-color);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: left;
    border: 1px solid transparent;
    transition: all 400ms ease;
    display: flex;
    align-items: center;
  }
  
  .contact__option:hover {
    background: transparent;
    border-color: var(--btn-secondary-bg);
    color: var(--link-color);
  }
  
  .contact__options-icon {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  
  .contact__info {
    flex: 1;
  }
  
  .contact__info h4 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .contact__info h5 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .contact_itext {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  
  .contact__option a {
    margin-top: 0.5rem;
    display: inline-block;
    font-size: 0.8rem;
  }
  
  /* ========== FORM ========== */
  form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 100%;
  }
  
  label {
    color: var(--link-color);
  }
  
  input,
  textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--primary-color);
    resize: none;
    color: var(--heading-color);
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  /* ========== MEDIA QUERIES (MEDIUM DEVICES) ========== */
  @media screen and (max-width: 1024px) {
    .container.contact__container {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }
  
  /* ========== MEDIA QUERIES (SMALL DEVICES) ========== */
  @media screen and (max-width: 600px) {
    .container.contact__container {
      width: 90%;
    }
  
    .kontakt__img {
      width: 100%;
    }
  
    .contact__options {
      flex-direction: column;
      align-items: flex;
    }
  
    .contact__option {
      flex-direction: column;
      align-items: flex;
    }
  
    .contact__options-icon {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  
    .contact__info h4, .contact__info h5, .contact__option a {
      margin: 0;
      text-align: center;
    }

    .contact_atext {
      padding-left: 55px;
    }
  }
  
  @media screen and (max-width: 400px) {
    .contact__options {
      gap: 10px;
    }
  
    .kontakt__img {
      padding-left: 15px;
      padding-top: 25px;
    }
  }
  