.services__top-contentb {
  text-align: center;
  margin-bottom: 50px;
}

.animation {
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.animation.active{
  transform: translateY(0);
  opacity: 1;
}


.service__item-wrapper3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  justify-content: center;
}

.service__item-wrapper1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  padding-top: 2rem;
}

.services__item3 {
  background: var(--card-bg);
  width: 250px;
  height: 175px;
  padding: 30px 15px;
  text-align: center;
  border-radius: 0.5rem;
  cursor: pointer;
}

.service__icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background: var(--icon-bg);
  border-radius: 5px;
  margin-bottom: 30px;
  border: 1px solid var(--primary-color);
}

.light-theme .service__icon {
  background-color: var(--primary-color);
}

.light-theme .service__icon i {
  color: #fff;
}

.service__icon i {
  font-size: 2rem;
  color: var(--primary-color);
}

.service__title {
  color: var(--primary-color);
  font-size: 1.3rem;
  margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {
  .services__top-content h2 {
    font-size: 1.5rem;
  }

  .service__item-wrapper {
    flex-wrap: wrap;
  }
  .service__item-wrapper3 {
    flex-wrap: wrap;
  }
  .services__item3 {
    width: 31%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .services__item3 {
    width: 47.7%;
  }
  .service__title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 576px) {
  .services__item3 {
    width: 100%;
  }
}
