.faq-top {
  background-color: #0d397a;
  padding-top: 50px;
}

.faq__img {
  width: 60%;
}

.faq__img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 25px;
  padding-top: 3.5rem;
}

.faq {
  text-align: center;
  padding-top: 50px;
}

.question-section {
  background: transparent;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  width: 26vw;
  color: white;
  margin-bottom: 10px; /* Abstand zwischen den Fragefeldern */
}

.faq-sec {
  display: flex;
  justify-content: center;
  margin-top: 1px;
}

.faq-qa {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 5px;
}

.question-align {
  align-items: center;
  display: flex;
  min-height: 10px;
  text-align: left;
}

.question-align h4 {
  margin-left: 8px;
}

.question-styling {
  font-size: 17px;
  width: 100%;
}

.question-icon {
  background: none;
  border: none;
  color: #1b6aae;
  cursor: pointer;
  font-size: 22px;
  margin-left: auto;
  margin-right: 8px;
}

.rotate {
  transform: rotate(45deg);
}

.answer-divider {
  border-top: 1px solid lightgray;
}

.answer {
  margin-left: 8px;
  margin-right: 8px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease, padding 0.6s ease;
  text-align: left;
}

.answer p {
  padding: 10px 0;
}

/* Media Queries für kleinere Geräte */
@media (max-width: 768px) {
  .faq__img {
    width: 100%;
  }

  .question-section {
    width: 80vw;
  }

  .faq-sec {
    margin-top: 5px;
  }

  .question-align h4 {
    font-size: 16px;
  }

  .question-icon {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .question-section {
    width: 90vw;
  }

  .faq-sec {
    margin-top: 8px;
  }

  .question-align h4 {
    font-size: 15px;
  }

  .question-icon {
    font-size: 18px;
  }
}
