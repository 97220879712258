.expand-top {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.expand-card {
  width: 50vw;
  background-color: var(--btn-primary-bg);
  border-radius: 0.5em;
  box-shadow: 0 1em 10px rgba(0, 0, 100, 0.2);
  padding: 1em;
  color: var(--logo);
  margin-bottom: 25px;

}

details {
  transition: 0.5s ease-in-out;
  text-align: center;
}

details[open] summary ~ * {
  animation: reveal 0.5s alternate ease-in-out;
}

details {
  transition: 0.4s ease-in-out;
}

details p {
    padding-top: 10px;
}
@keyframes reveal {
  0% {
    margin-left: -10px;
    opacity: 0;
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}
